<template>
    <div class="news-page">
        <div class="cont">
            <div class="wid1200">
                <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
            </div>
            <div :style="{minHeight:minH}">
                <h5 class="title">
                    <span>{{pageName}}</span>
                </h5>
                <el-row :gutter="20" class="list">
                    <el-col :span="12" class="addborder" v-for="iterm in latestNews" :key="iterm.id">
                        <div class="grid-content bg-purple  clear-fix">
                            <a v-if="$route.query.id == 'kfhq_kj' && !iterm.mediaPath"  style="cursor: auto;">{{iterm.title}}</a>
                            <a v-else @click="golink(iterm)"  >{{iterm.title}}</a>
                                <span>{{iterm.releaseDate}}</span></div>
                    </el-col>
                </el-row>
                <div class="page_box pagination-wrap">
                    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    components: {
        BreadCrumb
    },
    data() {
        return {
            pN: '',
            pageName: '资讯列表',
            latestNews: [],
            minH: '',
            currentPage: 1,
            pageSize: 20,
            total: 0,
            newsId: ''
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.currentPage = val
            this.getLatestNews()
        },
        golink(item) {
            // var routerData = this.$router.resolve({
            //     name: 'infoDetail',
            //     params: {
            //         id: id
            //     }
            // })


            if (this.$route.query.id == 'kfhq_kj') {
                const a = document.createElement('a')
                a.href = 'https://www.jikan.com.cn/admin/api/news/download?id='+item.id
                a.click()
            } else {
                if (item.jumpUrl) {
                    window.open(item.jumpUrl, '_blank')
                } else if (item.originUrl) {
                    window.open(item.originUrl, '_blank')
                } else if (item.mediaType=='pdf' && item.mediaPath) {
                    // pdf
                    window.open(item.mediaPath, '_blank')
                } else {
                    this.$router.push({path: '/nD/n', query: {id: item.id, pN: encodeURI(this.pageName)}})
                }
            }


        },
        getLatestNews() {
            if (this.newsId) {
                this.getAllData()
            } else {
                this.getData()
            }
        },
        getAllData() {
            var _this = this
            _this.$http
                .get('/admin/api/news/list', {
                    params: {
                        pageSize: 20,
                        pageNum: this.currentPage,
                        innerCode: this.newsId
                    }
                })
                .then(res => {
                    this.latestNews = res.data.data.rows
                    this.total = res.data.data.total
                })
        },
        getData() {
            var _this = this
            _this.$http
                .get('/admin/api/news/list', {
                    params: {
                        pageSize: 20,
                        pageNum: this.currentPage
                    }
                })
                .then(res => {
                    this.latestNews = res.data.data.rows
                    this.total = res.data.data.total
                })
        }
    },
    created() {
        this.minH = document.documentElement.clientHeight - 538 + 'px'
        if (this.$route.query.title) {
            this.pageName = this.$route.query.title
        }
        if (this.$route.query.id) {
            // this.pN = decodeURI(this.$route.query.pN)
            this.newsId = this.$route.query.id
            if (this.$route.query.id == 'kfhq_sjj') {
                this.pageName = 'OA数据集'
            } else if (this.$route.query.id == 'kfhq_kj' ) {
                this.pageName = 'OA课件'
            }
        } else {
            this.newsId = ''
        }
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        this.getLatestNews()
    }
}
</script>
<style scoped>
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.braed >>> .el-breadcrumb__inner.is-link:hover {
    color: #333;
    cursor: pointer;
}
.title {
    border-left: 6px solid #BD1A2D;
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 12px;
    color: #292929;
}
.title > span {
    display: inline-block;
    margin-left: 1px;
    text-indent: 10px;
    border-left: 4px solid #BD1A2D;
    font-size: 20px;
    line-height: 33px;
}
.list .el-col {
    /*line-height: 48px;*/
}
.addborder {
    border-right: 1px solid #e5e5e5;
}
.date {
    color: #606266;
}
.bg-purple {
    line-height: 48px;
    border-bottom: 1px dashed #e6e6e6;
    /*text-align: right;*/
}
.addborder:nth-child(odd) .bg-purple {
    margin-right: 30px;
}
.addborder:nth-child(even) .bg-purple {
    margin-left: 30px;
}
.bg-purple a {
    float: left;
    margin-left: 10px;
    overflow: hidden;
    width: 450px;
    height: 48px;
    /*display: inline-block;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    font-size: 16px;
}
.addborder:nth-child(odd) {
    padding-right: 30px;
}
.addborder:nth-child(even) {
    border-right: none;
}
.bg-purple span {
    float: right;
    font-size: 16px;
}
.bg-purple a:hover {
    /* font-weight: bold; */
}
.page_box {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: nowrap;
}
.el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
